import { Injectable } from '@angular/core';
import 'rxjs/add/operator/map';
import { Observable, of } from 'rxjs';
import { ServiceAbstract, ResponseSuccess } from '@senac/architecture';
import { environment } from '../../../../environments/environment';
import { Unidade } from '@bo/shared/models/regional/unidade.model';

@Injectable()
export class UnidadeService extends ServiceAbstract<Unidade> {

  getEndpoint() {
    return 'unidade';
  }

  getEnvironment() {
    return environment;
  }

  getAll(): Observable<Unidade[]> {
    return this.http.get<ResponseSuccess<Unidade[]>>(this.getUri(), this.getOptions())
      .map((res) => res.data)
      .catch(error => of());
  }

}