import { ErrorHandling } from "@bo/shared/bases/error-handling.abstract";
import { Injectable } from "@angular/core";
import { Effect, Actions, ofType } from "@ngrx/effects";
import { Observable } from "rxjs";
import { Action } from "@ngrx/store";
import { switchMap, map } from "rxjs/operators";
import { USER_AUTHENTICATED_LOAD, UserAuthenticatedLoadAction, UserAuthenticatedLoadedAction, USER_AUTHENTICATED_REFRESH, UserAuthenticatedRefreshAction, UserAuthenticatedRefreshedAction } from "../actions/user-authenticated.actions";
import { UserAuthenticated } from "@senac/authentication";
import { AuthBOService } from "@bo/shared/authentication/services/auth-bo.services";
import { User } from "@bo/shared/models/authentication/user.model";

@Injectable()
export class UserAuthenticatedEffects extends ErrorHandling {

  @Effect()
  loadUserAuthenticated$: Observable<Action> = this.actions$.pipe(
    ofType(USER_AUTHENTICATED_LOAD),
    switchMap((action: UserAuthenticatedLoadAction) => this.authBOService.getUserAuthenticated()),
    map((user: UserAuthenticated<User, any>) => new UserAuthenticatedLoadedAction(user)),
  );

  @Effect()
  loadUserAuthenticatedRefreshed$: Observable<Action> = this.actions$.pipe(
    ofType(USER_AUTHENTICATED_REFRESH),
    switchMap((action: UserAuthenticatedRefreshAction) => this.authBOService.getRefreshUserAuthenticated()),
    map((user: UserAuthenticated<User, any>) => new UserAuthenticatedRefreshedAction(user)),
  );

  constructor(
    public actions$: Actions,
    private authBOService: AuthBOService,
  ) {
    super(actions$);
  }

}