import { Injectable } from '@angular/core';
import { environment } from './../../../../environments/environment';

import { ServiceAbstract, ResponseSuccess, ResponseSuccessPaginated, ResultPaginated } from '@senac/architecture';
import { Candidato } from '@bo/shared/models/candidato/candidato.model';
import { Observable, of } from 'rxjs';
import { FiltroBancoDeCandidatos } from '@bo/shared/models/candidato/filtro-banco-de-candidatos';
import { serializeObject, FileUtils } from '@senac/helpers';
import { Endereco } from '@bo/shared/models/ofertante/endereco.model';

@Injectable()
export class CandidatoService  extends ServiceAbstract<Candidato> {

  getEndpoint() : string {
    return 'candidato';
  }

  getEnvironment() {
    return environment;
  }

  getCurriculoByCandidatoLogado() : Observable<Candidato> {

    return this.http.get<ResponseSuccess<Candidato>>(`${this.getUri()}`, this.getOptions())
      .map((res) => res.data)
      .catch(error => of())
  }

  downloadCurriculo(candidato: Candidato) {
    return this.http.get<ResponseSuccess<Candidato>>(`${this.getUri()}/download-curriculo?candidatoId=${candidato.id}`, this.getOptions())
      .map((res) => res.data)
      .catch(error => of())
      .subscribe(contentBase64 => {
        let fileName = `${candidato.pessoaFisica.nome}-Currículo.pdf`,
          contentType = 'application/pdf';

        FileUtils.download(fileName, contentType, contentBase64);
      });
  }

  importCursosGetCurriculoByCandidatoLogado() : Observable<Candidato> {
    return this.http.get<ResponseSuccess<Candidato>>(`${this.getUri()}/importar-cursos-get-candidato`, this.getOptions())
      .map((res) => res.data)
      .catch(error => of())
  }

  getPaginated(page: number = 1, pageSize: number, filter?: FiltroBancoDeCandidatos): Observable<ResultPaginated<Candidato>> {
    let filtroFormatado = '';

    if (filter)
      filtroFormatado = '&'+serializeObject(filter, null);

    return this.http.get<ResponseSuccessPaginated<Candidato>>(this.getUri() + `/get-paginated?page=${page}&pageSize=${pageSize}` + filtroFormatado, this.getOptions())
      .map((res) => res.data)
      .catch(error => of())
  }

  updateAvatar(base64 : string) : Observable<string> {
    return this.http.post<ResponseSuccess<string>>(`${this.getUri()}/update-avatar`, JSON.stringify({imagem: base64}), this.getOptions())
      .map((res) => base64)
      .catch(error => of(''));
  }
}
